import { createSlice } from '@reduxjs/toolkit'
import { fetchPopulation } from '../fetch/population';
const populationSlice = createSlice({
  name: 'population',
  initialState: {
    isLoading: false,
    value: [],
    isError: false
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPopulation.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchPopulation.fulfilled, (state, action) => {
      state.isLoading = false;
      let data = action.payload;
      if (data && data.constructor === Array)
        state.value = data;
    })
    builder.addCase(fetchPopulation.rejected, (state, action) => {
      state.isError = true;
    })
  },
  reducers: {


    // incremented: state => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decremented: state => {
    //   state.value -= 1
    // },
    // setFamily: (state, action) => {
    //   state.value = action.list;
    // },
  }
})
export const selectPopulation = (state) => state.population.value

export const selectLoadingPopulation = (state) => state.population.isLoading

export default populationSlice.reducer


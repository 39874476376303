
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from './Slider.js';
import { selectLangObj } from '../reducers/langReducer.js';


var links = [

  {
    url:"https://www.youtube.com",
    title:"YouTube"
    
  },  {
    url:"https://backapalankavesti.com/",
    title:"BAP Dešavanja"
    
  },  {
    url:"https://www.youtube.com",
    title:"YouTube"
    
  },,  {
    url:"https://www.youtube.com",
    title:"YouTube"
    
  },


]

function MainLinks(props) {
  const langObj = useSelector(selectLangObj);

  
  return (
     /* {
            "рбр": 24,
            "тип": "Фото",
            "име": "АЦ0001",
            "наслов": "фотографија",
            "датум": "2024-10-29",
            "прикупио": "С. Тришић",
            "период": 0,
            "линк": "https://i.ibb.co/sRBNGHV/img002.jpg",
            "линкП": "https://ibb.co/9gM1Ltr"
        }, */
           <Slider title={langObj["Links"]} items={links}></Slider>
  );
}

export default MainLinks;

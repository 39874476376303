import { createSlice } from '@reduxjs/toolkit'
import { fetchRecentDocuments } from '../fetch/recentDocuments';
const recentDocumentsSlice = createSlice({
  name: 'recentDocuments',
  initialState: {
    isLoading: false,
    value: [],
    isError: false,
    page:0
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecentDocuments.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(fetchRecentDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      let data = action.payload.items;
      if (data && data.constructor === Array){
        state.value.push(...data);
        state.page++;
      }
    })
    builder.addCase(fetchRecentDocuments.rejected, (state, action) => {
      state.isError = true;
    })
  },
  reducers: {


    // incremented: state => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decremented: state => {
    //   state.value -= 1
    // },
    // setFamily: (state, action) => {
    //   state.value = action.list;
    // },
  }
})
export const selectRecentDocuments = (state) => state.recentDocuments.value
export const selectLoadingRecentDocuments = (state) => state.recentDocuments.isLoading
export const selectPageRecentDocuments = (state) => state.recentDocuments.page;

export const {} = recentDocumentsSlice.actions

export default recentDocumentsSlice.reducer


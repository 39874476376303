import { createSlice } from '@reduxjs/toolkit'
var sr = require("../lang/sr.json");
var src = require("../lang/srcir.json");
var en = require("../lang/en.json");

const langSlice = createSlice({
  name: 'lang',
  initialState: {
    isLoading: false,
    value: "sr",
    isError: false,
    langObj: sr
  },


  reducers: {


    // incremented: state => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decremented: state => {
    //   state.value -= 1
    // },
    setLang: (state, action,) => {
      state.value = action.payload;
      switch (state.value) {
        case "sr":
          state.langObj = sr;
          break;
        case "src":
          state.langObj = src;
          break;
        case "en":
          state.langObj = en;
          break;

        default:
          break;
      }
    }
  },

});
export const selectLang = (state) => state.lang.value
export const selectLangObj = (state) => state.lang.langObj

export const { setLang: setLang } = langSlice.actions

export default langSlice.reducer


import * as Constants from '../Constants'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSchools = createAsyncThunk("fetchSchools", async (surname) => {

  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "ApiKeyTest");
  myHeaders.append('Access-Control-Allow-Origin', Constants.PATH);
  myHeaders.append('Access-Control-Allow-Credentials', 'true');

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    dataType:"json"
  };
  const res = await fetch(Constants.API_ROOT + `/api/DefaultSearch/GetSkole?` + new URLSearchParams({
    surname:surname
}), requestOptions);
  return res?.json();
});

import { createSlice } from '@reduxjs/toolkit'
import { fetchStreets } from '../fetch/streets';
const streetsSlice = createSlice({
  name: 'streets',
  initialState: {
    isLoading: false,
    value: [],
    isError: false
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStreets.pending, (state, action) => {
     state.isLoading = true;
    })
    builder.addCase(fetchStreets.fulfilled, (state, action) => {
     state.isLoading = false;
     let data = action.payload;
      if (data && data.constructor === Array)
        state.value = data;
    })
    builder.addCase(fetchStreets.rejected, (state, action) => {
     state.isError = true;
    })
   },
  reducers: {


    // incremented: state => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decremented: state => {
    //   state.value -= 1
    // },
    // setFamily: (state, action) => {
    //   state.value = action.list;
    // },
  }
})
export const selectStreets = (state) => state.streets.value
export const selectLoadingStreets = (state) => state.streets.isLoading


export default streetsSlice.reducer


import { useState } from "react";

import { PhotoIcon, LinkIcon, XCircleIcon } from '@heroicons/react/24/solid'

function SliderElement({ data }) {
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    const isEmpty = data.url == null;
    const isImage = data.url && (data.url.endsWith(".jpg") || data.url.endsWith(".png") || data.url.endsWith(".gif") || data.url.endsWith(".webp"));
    const isVideo = data.url && (data.url.endsWith(".mp4") || data.url.endsWith(".avi") || data.url.endsWith(".mkv"));
    const isLink = data.url && (!isImage && !isVideo);
    const imageStyle = !isImage && !loaded ? { display: "none" } : {};

    return (
        <a href={data.url} target="_blank">


            <div className="rounded-md bg-slate-600 bg-opacity-50 w-60 h-60 relative overflow-hidden flex-shrink-0">

                <img className="absolute top-0 left-0 w-full h-full object-cover z-1" src={data.url} style={imageStyle} onError={() => setLoading(false)} onLoad={() => { setLoaded(true); setLoading(false) }}></img>
                {
                    (!loading && loaded) || (<div className="absolute top-0 left-0 w-full h-full bg-white text-slate-800 z-1 flex justify-center items-center"><PhotoIcon className="size-10"></PhotoIcon></div>)

                }
                {
                    isLink && (<div className="absolute top-0 left-0 w-full h-full bg-white text-blue-950 z-1 flex justify-center items-center"><LinkIcon className="size-10"></LinkIcon></div>)

                }
                {
                    isEmpty && (<div className="absolute top-0 left-0 w-full h-full bg-white text-blue-950 z-1 flex justify-center items-center"><XCircleIcon className="size-10"></XCircleIcon></div>)

                }
                {
                    isImage && loading && (

                        <div className='w-full h-full backdrop-blur-lg  absolute top-0 left-0 z-50 flex justify-center items-center'>
                            <div className="loader"></div>
                        </div>
                    )
                }
                {/* <video className="absolute top-0 left-0 w-full h-full" onLoad={()=>setLoaded(true)}>

                    <source src={data.url} type="video/mp4" />

                </video> */}
                <div className="flex flex-col h-full  z-10">
                    {data.date && (
                        <div className="self-end rounded-bl-lg text-white bg-beige-500 p-1 z-10">

                            {data.date}
                        </div>)
                    }
                    <div className="flex-grow"></div>
                    <div className="text-white bg-slate-500 bg-opacity-75 backdrop-blur-sm  z-10 p-1">

                        {data.title}
                    </div>
                </div>

            </div>
        </a>
    );
}

export default SliderElement;

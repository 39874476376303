
import { selectFamily } from '../reducers/familiesReducer';
import { selectPopulation } from '../reducers/populationReducer';
import { selectSchools } from '../reducers/schoolsReducer';
import { selectStreets } from '../reducers/streetsReducer';
import { useSelector } from 'react-redux';
import Row from './Row';
import { useState, useMemo } from 'react';

import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  RowData,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { type } from '@testing-library/user-event/dist/type';



function SearchLayout(props) {

  const family = useSelector(selectFamily);
  const population = useSelector(selectPopulation);
  const schools = useSelector(selectSchools);
  const streets = useSelector(selectStreets);

  const [detail, setDetails] = useState(null);


  const [columnFilters, setColumnFilters] = useState(
    []
  )
  const columns = useMemo(() => [
    // ...
  ], []);

  const [data, setData] = useState(() => [
    // ...
  ]);

  // Columns and data are defined in a stable reference, will not cause infinite loop!
  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })


  const switchRows = (type) => {

    switch (type) {
      case "family":
        return family.map(a => {

          return (<Row onClick={() => setDetails(a)} Title={a.prezime} Context={a.imeOca} Text={a.naselje + ", " + a.opstina + ", " + a.kraj}></Row>)

        })
      case "people":
        return population.map(a => {

          return (<Row onClick={() => setDetails(a)} Title={a.prezime} Context={a.imeOca} Text={a.naselje + ", " + a.opstina + ", " + a.kraj}></Row>)

        })
      case "streets":
        return streets.map(a => {

          return (<Row onClick={() => setDetails(a)} Title={a.nazivUlice} Context={a.sinonim} Text={a.oznakaMesta}></Row>)

        })
      case "school":
        return schools.map(a => {

          return (<Row onClick={() => setDetails(a)} Title={a.naziv} Context={a.godU} Text={a.oznaкаMesta}></Row>)

        })
      default:
        return "";

    }


  }
  
  const switchLength = (type) => {

    switch (type) {
      case "family":
        return family && family.length

      case "people":
        return population && population.length
      case "streets":
        return streets&& streets.length
      case "school":
        return schools&& schools.length
      default:
        return 0;

    }


  }
  return (


    <div className="flex gap-6 flex-grow items-stretch z-10" style={{ minHeight: 0 }}>

      <div className=' h-full flex-grow rounded-lg backdrop-blur-xl bg-slate-500 bg-opacity-50 border  p-3 border-gray-600'>
        <div className='h-full overflow-y-auto flex flex-col gap-3'>
          <div className='self-start text-white'>
            {family && family.isLoading && <b>Loading...</b>}
            {switchLength(props.Type) + " rows found."}


          </div>
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort()
                                  ? 'cursor-pointer select-none'
                                  : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted()] || null}
                            </div>
                            {header.column.getCanFilter() ? (
                              <div>
                                {/* <Filter column={header.column} /> */}
                              </div>
                            ) : null}
                          </>
                        )}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>          
          
          {
            switchRows(props.Type)
          }
        </div>

      </div>
      <div className='text-white rounded-lg backdrop-blur-xl bg-slate-500 border border-gray-600 basis-1/3 flex-shrink-0 p-3 flex flex-col gap-3'>
        <div className='flex items-end gap-3'>
          <div className='rounded-full relative overflow-hidden' style={{ height: '2rem', width: '2rem' }}>
            <img src={require("../images/user.png")} className='absolute w-full h-full top-0 left-0'></img>
          </div>
          <h3>
            Титле
          </h3>
        </div>
        {detail && <hr className='border self-stretch border-slate-600'></hr>}

        <div className='flex flex-col items-start gap-3'>

          {
            detail &&
            Object.keys(detail).map(e => {
              return (<div className=''><b>{e}</b> - {detail[e]} </div>)
            })
          }
        </div>
      </div>
    </div>
  );
}

export default SearchLayout;

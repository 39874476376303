import { selectRecentDocuments } from '../reducers/recentDocumentsReducer';
import { fetchRecentDocuments } from '../fetch/recentDocuments.js';
import { selectLoadingRecentDocuments , selectPageRecentDocuments} from '../reducers/recentDocumentsReducer.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from './Slider.js';
import { selectLangObj } from '../reducers/langReducer.js';


function RecentDocuments(props) {
  const loadingRecentDocuments = useSelector(selectLoadingRecentDocuments);
  const recentDocuments = useSelector(selectRecentDocuments);
  const page = useSelector(selectPageRecentDocuments);
  const dispatch = useDispatch();
  const langObj = useSelector(selectLangObj);
 
  const appendItems = ()=>{
    dispatch(fetchRecentDocuments(page));
  }
  useEffect(() => {

    appendItems();
  },[]);
  
  return (
     /* {
            "рбр": 24,
            "тип": "Фото",
            "име": "АЦ0001",
            "наслов": "фотографија",
            "датум": "2024-10-29",
            "прикупио": "С. Тришић",
            "период": 0,
            "линк": "https://i.ibb.co/sRBNGHV/img002.jpg",
            "линкП": "https://ibb.co/9gM1Ltr"
        }, */
           <Slider title={langObj["Documents"]} onEnd={()=>appendItems()} items={recentDocuments.map(e=>{return {url:e.линк, title:e.наслов, date:e.датум}})}></Slider>
  );
}

export default RecentDocuments;

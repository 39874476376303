import { useRef, useState } from "react";
import SliderElement from "./SliderElement";

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
var winmd = window.matchMedia("(min-width: 768px)")

function Slider({ title, items, onEnd, endPercent}) {
    const [scrollLeft, setScrollLeft] = useState(false);
    const [scrollRight, setScrollRight] = useState(true);
    const ref = useRef(null);
    const scrollStep = 200;
    const scroll = (scrollOffset) => {
        let left = ref.current.scrollLeft + scrollOffset;

        ref.current.scroll({
            top: 0, 
            left: left, 
            behavior: 'smooth'
          });
      };

      const endRatio = endPercent||0.99;

    return (
        <div className="flex gap-1 flex-col  w-full">

            <div className=" w-full flex items-center gap-3 relative">

                <div className=" p-3 flex gap-1 flex-col  flex-grow rounded-lg bg-slate-500 bg-opacity-50" style={{ minWidth: 0 }}>

                    <div className="text-white text-2xl self-start">
                        {title}
                    </div>
                    <div className=" flex-grow overflow-x-auto" ref={ref} onScroll={(e => {
                        if (e.currentTarget.scrollLeft > 0) {
                            setScrollLeft(true);
                        } else {
                            setScrollLeft(false);

                        }
                        let el = e.currentTarget;
                        if (el.scrollWidth - el.scrollLeft - el.clientWidth == 0) {
                            setScrollRight(false);
                        } else {
                            setScrollRight(true);

                        }

                        if(( el.scrollLeft + el.clientWidth)/el.scrollWidth > endRatio){
                            onEnd();

                        }

                    })} >
                        <div className="flex gap-3">
                            {
                                items && items.map(e => {

                                    return (
                                        <SliderElement data={e}></SliderElement>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
                {

                    (scrollLeft&&winmd.matches) &&
                    (

                        <div className="flex justify-center items-center h-full p-3 absolute md:left-5 left-1 ">

                            <button onClick={()=>scroll(-scrollStep)} className="bg-beige-500 rounded-full text-slate-600 hover:bg-beige-700 p-3 transition-all hover:scale-110 ">
                                <ChevronLeftIcon className=" size-4 md:size-8 text-slate-600"></ChevronLeftIcon>
                            </button>
                        </div>


                    )
                }
                {

                    (scrollRight&&winmd.matches) &&
                    (
                        <div className="flex justify-center items-center h-full p-3 absolute md:right-5 right-1 ">

                            <button onClick={()=>scroll(scrollStep)}  className="bg-beige-500 rounded-full  hover:bg-beige-700 p-3 transition-all hover:scale-110">
                                <ChevronRightIcon className="size-4 md:size-8 text-slate-600" />
                            </button>
                        </div>
                    )}
            </div>
        </div>

    );
}

export default Slider;



function Row(props) {

  return (
    <div onClick={props.onClick} className="flex items-start gap-3 p-3 flex-col border border-gray-700 bg-white cursor-pointer">
            <h5>
              {props.Title} ({props.Context})
            </h5>
            <hr className='border self-stretch border-slate-600'></hr>

            <p>
              {props.Text}
            </p>

    </div>
  );
}

export default Row;



function SquareButton(props) {

  return (

    <button onClick={props.onClick} className={props.classAdd + " text-sm md:text-xl transition-all md:hover:scale-110 flex flex-row justify-center items-center  p-2 text-white h-16 w-16 md:h-40 md:w-40"}>
      {props.name}

    </button>
  );
}

export default SquareButton;

import { createSlice } from '@reduxjs/toolkit'
import { fetchSchools } from '../fetch/school';
const schoolsSlice = createSlice({
  name: 'schools',
  initialState: {
    isLoading: false,
    value: [],
    isError: false
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchools.pending, (state, action) => {
     state.isLoading = true;
    })
    builder.addCase(fetchSchools.fulfilled, (state, action) => {
     state.isLoading = false;
     let data = action.payload;
      if (data && data.constructor === Array)
        state.value = data;
    })
    builder.addCase(fetchSchools.rejected, (state, action) => {
     state.isError = true;
    })
   },
  reducers: {


    // incremented: state => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1
    // },
    // decremented: state => {
    //   state.value -= 1
    // },
    // setFamily: (state, action) => {
    //   state.value = action.list;
    // },
  }
})
export const selectSchools = (state) => state.schools.value

export const selectLoadingSchools = (state) => state.schools.isLoading

export default schoolsSlice.reducer


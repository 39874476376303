import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';
import SearchLayout from './components/SearchLayout';
import { useSelector, useDispatch } from 'react-redux';

import { fetchFamilies } from './fetch/family.js';
import { useInterval } from "./utility/interval.js";

import { fetchPopulation } from './fetch/population.js';
import { fetchStreets } from './fetch/streets.js';
import { fetchSchools } from './fetch/school.js';
import { useTimeout } from './utility/timeout.js';
import { selectLoadingFamilies } from './reducers/familiesReducer.js';
import { selectLoadingStreets } from './reducers/streetsReducer.js';
import { selectLoadingSchools } from './reducers/schoolsReducer.js';
import { selectLoadingPopulation } from './reducers/populationReducer.js';
import { selectLang, setLang, selectLangObj } from './reducers/langReducer.js';
import { selectLoader, setLoading } from './reducers/loaderReducer.js';
import LangButton from './components/lang/LangButton.js';
import SquareButton from './components/elements/SquareButton.js';
import Slider from './components/Slider.js';
import BackGround from './components/Background.js';
import RecentDocuments from './components/RecentDocuments.js';
import MainLinks from './components/MainLinks.js';


// Create a MediaQueryList object
var winmd = window.matchMedia("(min-width: 768px)")


function App() {
  const [term, setTerm] = useState("");
  const [showLang, setShowLang] = useState(false);
  const [searchOn, setSearchOn] = useState(false);
  const [searchState, setSearchState] = useState("family");
  const lang = useSelector(selectLang);
  const langObj = useSelector(selectLangObj);
  const loading = useSelector(selectLoader);
  const loadingFamilies = useSelector(selectLoadingFamilies);
  const loadingStreets = useSelector(selectLoadingStreets);
  const loadingSchools = useSelector(selectLoadingSchools);
  const loadingPopulation = useSelector(selectLoadingPopulation);


  const dispatch = useDispatch();
  //dispatch(fetchFamilies());



  function bgSearch(state) {

    // if(searchState && state && state != searchState){
    //   return " bg-white text-black  ";
    // }

    return (state == searchState ? " rounded font-semi-bold backdrop-blur-xl bg-slate-700 bg-opacity-50" : _bgSearch(state) + "  font-semi-bold ");
  }

  function _textSearch(state) {

    let stateSw = state || searchState;
    switch (stateSw) {
      case "family":
        return "text-teal-800"
      case "people":
        return "text-yellow-500"
      case "streets":
        return "text-orange-400"
      case "school":
        return "text-red-600"
      default:
        return "";
    }
  }
  function _bgSearch(state) {

    let stateSw = state || searchState;
    switch (stateSw) {
      case "family":
        return "bg-teal-800"
      case "people":
        return "bg-yellow-500"
      case "streets":
        return "bg-orange-400"
      case "school":
        return "bg-red-600"
      default:
        return "";
    }
  }



  function IconSearch() {
    if (!searchOn) {
      return;
    }
    switch (searchState) {
      case "family":
        return (
          <button onClick={() => { setSearchOn(true); setSearchState("family"); }} className={_bgSearch("family") + " rounded-s transition-all hover:scale-110 flex flex-row justify-center items-center  p-2 text-white"}>
            {langObj["App"]["Families"]}
          </button>
        )
      case "people":
        return (
          <button onClick={() => { setSearchOn(true); setSearchState("people"); }} className={_bgSearch("people") + " rounded-s transition-all hover:scale-110 flex flex-row justify-center items-center  p-2 text-white"}>
            {langObj["App"]["People"]}
          </button>

        )
      case "streets":
        return (
          <button onClick={() => { setSearchOn(true); setSearchState("streets"); }} className={_bgSearch("streets") + " rounded-s transition-all hover:scale-110 flex flex-row justify-center items-center  p-2 text-white"}>
            {langObj["App"]["Streets"]}
          </button>
        )
      case "school":
        return (
          <button onClick={() => { setSearchOn(true); setSearchState("school"); }} className={_bgSearch("school") + " rounded-s transition-all hover:scale-110 flex flex-row justify-center items-center   p-2 text-white"}>
            {langObj["App"]["School"]}
          </button>
        )
      default:
        return "";
    }




  }
  function Search() {
    setSearchOn(true);
    switch (searchState) {
      case "family":
        dispatch(fetchFamilies(term));

        break;
      case "people":
        dispatch(fetchPopulation(term))
        break;
      case "streets":
        dispatch(fetchStreets(term))
        break;
      case "school":
        dispatch(fetchSchools(term))
        break;
      default:
        return;
    }




  }



  return (
    <div className="App flex flex-col">


      <div className='h-screen flex flex-col  relative '>
        {
          loading || loadingFamilies || loadingPopulation || loadingSchools || loadingStreets ?
            (
              <div className='w-full h-full backdrop-blur-lg  absolute top-0 left-0 z-50 flex justify-center items-center'>
                <div className="loader"></div>
              </div>
            ) :
            ""
        }
        <header className="App-header bg-beige-400  flex-shrink-0">
          <div class="flex flex-row justify-start items-stretch flex-shrink-0 gap-3 w-full">

            <div class="relative h-20 w-20">
              <img src={require("./images/logoIconCropped.jpg")} className='h-full w-full absolute top-0 left-0 object-cover'>

              </img>
            </div>

            <h4 className='md:text-2xl text-lg self-center text-slate-600 '>
              {langObj["AppTitle"]}
            </h4>

            <div class="flex flex-row justify-start items-stretch  flex-grow flex-shrink-0">

              {/* <h1>Arhiva Gajdobra</h1> */}
              <div className='flex-grow'></div>
              <div className={(winmd.matches ? "" : "relative") + " flex flex-row justify-start items-stretch"}>

                {winmd.matches ||

                  <button className='self-center' onClick={() => {
                    setShowLang(!showLang);
                  }}>
                    🌐
                  </button>
                }
                {(winmd.matches || showLang) && (

                  <div class={(winmd.matches ? "" : "absolute top-full right-0 ") + " z-40 flex flex-row justify-start items-stretch"}>



                    <LangButton onClick={() => dispatch(setLang("sr"))} name="SR" selected={lang === "sr"} />
                    <LangButton onClick={() => dispatch(setLang("src"))} name="СР" selected={lang === "src"} />
                    <LangButton onClick={() => dispatch(setLang("en"))} name="EN" selected={lang === "en"} />
                    <LangButton onClick={() => dispatch(setLang("de"))} name="DE" selected={lang === "de"} />


                  </div>
                )

                }



              </div>





            </div>
          </div>
        </header>

        <div className={" justify-center  transition-all  flex-grow flex gap-3 flex-col relative"} style={{ minHeight: 0 }} >


          <BackGround />
          {/* <img src={"https://www.mb51p.com/foto/TT/TT" + image + ".jpg"} class="absolute top-0 left-0 w-full h-full object-cover"></img> */}
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <div className='w-full h-full overflow-y-scroll z-10  '>
            <div className={"  justify-center transition-all flex gap-3 flex-col mb-3 md:h-5/6"} >

              <div className={(searchOn ? "justify-start  items-stretch  " : " justify-center ") + '  self-stretch md:p-6 p-3 flex flex-col gap-3 '}>

                <div className={(searchOn ? "md:w-full" : "md:w-8/12") + " w-full  self-center transition-all flex flex-row "}>

                  <div className={" transition-all flex flex-col md:flex-row gap-6  w-full "}>

                    <div className={(searchOn ? "rounded-xl" : " rounded-tl-3xl ") + "  backdrop-blur-lg flex flex-col items-stretch    border border-gray-600  flex-grow basis-full"}>


                      <div className={(searchOn ? "opacity-0 hidden " : " opacity-100  ") + " flex flex-row justify-around items-center gap-3 p-6 pb-3"}>
                        <SquareButton onClick={() => { setSearchState("family"); }} name={langObj["App"]["Families"]} classAdd={bgSearch("family")} />
                        <SquareButton onClick={() => { setSearchState("people"); }} name={langObj["App"]["People"]} classAdd={bgSearch("people")} />
                        <SquareButton onClick={() => { setSearchState("streets"); }} name={langObj["App"]["Streets"]} classAdd={bgSearch("streets")} />
                        <SquareButton onClick={() => { setSearchState("school"); }} name={langObj["App"]["School"]} classAdd={bgSearch("school")} />
                      </div>

                      <div className={" flex flex-col md:flex-row gap-3 md:gap-0  p-6 pt-3 justify-between items-stretch"}>
                        {IconSearch()}
                        <input placeholder="Попарић нпр." value={term} onChange={e => setTerm(e.target.value)} class={(searchOn ? "rounded-e" : "rounded") + " me-3 flex-grow basis-full shadow appearance-none border  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"} id="username" type="text" />
                        <button onClick={() => Search()} class={(searchOn ? "me-3" : "") + " bg-lime-800 hover:bg-lime-900 text-white p-2 focus:outline-none focus:shadow-outline"} type="button">
                          Pretraga</button>

                        {
                          searchOn &&
                          (<button onClick={() => { setSearchOn(false); }} class=" rounded-e text-sm  bg-slate-700  hover:bg-slate-900  text-white p-2  focus:outline-none focus:shadow-outline" type="button">
                            {langObj["Back"]}
                          </button>)
                        }


                      </div>
                    </div>
                    <div className={(searchOn ? "opacity-0 hidden" : " opacity-100  ") + "  rounded-br-3xl transition-all flex flex-col justify-around   items-stretch text-wrap  gap-3 p-6 flex-shrink bg-amber-300 backdrop-blur-xl  bg-opacity-50 border border-orange-950 "}>
                      <p class="text-justify text-lg ">
                        {langObj["App"]["Intro1"]}
                      </p>
                      <p class="text-start text-lg font-semibold rounded-sm">
                        {langObj["App"]["Intro2"]}
                      </p>
                    </div>
                  </div>
                </div>
                {
                  searchOn && (
                    <SearchLayout Type={searchState}></SearchLayout>
                  )
                }
              </div>
            </div>

            <div className={"  justify-center flex flex-col"} >

              <div className='flex flex-col gap-6 md:p-6 p-3 border-t border-gray-600 bg-slate-500 bg-opacity-50 backdrop-blur-lg'>
                <RecentDocuments></RecentDocuments>
                <MainLinks />
              </div>

              <footer className='flex justify-end gap-3 p-1 bg-gray-700 text-gray-300'>
                Arhiva Gajdobra co.
              </footer>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;

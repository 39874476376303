import * as Constants from '../Constants'
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchRecentDocuments = createAsyncThunk("fetchRecentDocuments", async (page) => {

  let pageSize = 10;
  try {
    var myHeaders = new Headers();
    myHeaders.append("X-Api-Key", "ApiKeyTest");
    myHeaders.append('Access-Control-Allow-Origin', Constants.PATH);
    myHeaders.append('Access-Control-Allow-Credentials', 'true');
  
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
      dataType:"json"
    };
    const res = await fetch(Constants.API_ROOT + `/api/Documents/GetRecentDocuments?` + new URLSearchParams({
      pageNumber:page,
      pageSize:pageSize
  }), requestOptions);
    return res?.json();
  } catch (error) {
    return [];
  }
  

});